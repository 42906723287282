.gallery-container {
    text-align: center;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  
  .gallery-image {
    width: 100%;
    cursor: pointer;
  }
  
  .fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fullscreen-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  