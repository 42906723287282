.registration-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 40px;
    width: 90%;
    max-width: 900px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    overflow-y: auto;
    max-height: 90vh;
  }
  
  .registration-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .registration-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .registration-form input,
  .registration-form label,
  .registration-form button {
    font-size: clamp(0.5em, 1vw + 0.5em, 0.8em);
  }
  
  .registration-form input[type="text"],
  .registration-form input[type="email"],
  .registration-form input[type="tel"]{
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }

  .registration-form button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .registration-form input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .registration-form label {
    display: flex;
    align-items: center;
    font-size: clamp(0.5em, 1vw + 0.5em, 0.8em);
  }
  
  .registration-form button {
    background-color: #ff4081;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 12px;
    border-radius: 5px;
  }
  
  .registration-form button:hover {
    background-color: #e0356d;
  }
  