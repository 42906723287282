@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

body {
  font-family: 'Rubik', sans-serif;
}

.App {
  text-align: center;
  background-color: #ffffff;
  font-size: clamp(0.9em, 1vw + 1em, 1.2em);
  color: rgb(0, 0, 0);
}

.App-header {
  background-color: #0F7699;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: clamp(0.9em, 1vw + 1em, 1.2em);
  color: white;
}

.App-footer {
  background-color: #0F7699;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: clamp(0.8em, 1vw + 0.5em, 1em);
  color: white;
}

button {
  margin: 5px;
  padding: 10px;
  background-color: #38B6FF;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}

button:disabled {
  background-color: #B0B0B0;
  color: #FFFFFF;
  cursor: not-allowed;
  opacity: 0.6;
}

select {
  margin: 5px;
  padding: 1px;
  background-color: #ffffff; /* Gleiche Hintergrundfarbe wie die Buttons */
  border: 1px solid black; /* Schwarzer Rahmen */
  color: black; /* Textfarbe */
  cursor: pointer; /* Zeigt an, dass das Element interaktiv ist */
}

textarea {
  margin: 5px;
  padding: 1px;
  background-color: #ffffff; /* Gleiche Hintergrundfarbe wie die Buttons */
  border: 1px solid black; /* Schwarzer Rahmen */
  color: black; /* Textfarbe */
  cursor: pointer; /* Zeigt an, dass das Element interaktiv ist */
}

.custom-file-upload {
  margin: 5px;
  padding: 1px;
  background-color: #ffffff; /* Gleiche Hintergrundfarbe wie die anderen Buttons */
  border: 1px solid black; /* Schwarzer Rahmen */
  color: black; /* Textfarbe */
  cursor: pointer; /* Hand-Cursor anzeigen */
  display: inline-block;
}

p {
  font-size: clamp(0.8em, 1vw + 0.5em, 0.8em);
}

h1 {
  font-size: clamp(1.3em, 2vw + 1em, 2em);
}

h2 {
  font-size: clamp(1.2em, 2vw + 0.5em, 1.8em);
}

.logo-text-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 10px; /* Adjust this value if needed */
  left: 15px; /* Adjust this value if needed */
}

.logo {
  width: 155px; /* Adjust the size if necessary */
  margin-right: 10px;
}

.logo-text-container h4 {
  font-size: clamp(0.5em, 1vw + 0.5em, 0.8em);
  margin: 0;
}

.footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  background-color: #0F7699;
  color: #ffffff;
  padding: 40px;
  font-size: clamp(0.5em, 1vw + 0.5em, 0.8em);
}

.footer-column h4 {
  margin-bottom: 10px;
  font-size: clamp(0.5em, 1vw + 0.5em, 0.8em); 
}

.footer-column p,
.footer-column a {
  color: #ffffff;
  text-decoration: none;
  font-size: clamp(0.5em, 1vw + 0.5em, 0.8em);
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-bottom {
  background-color: #0F7699;
  color: #ffffff;
  text-align: center;
  padding: 10px;
}

.footer-bottom p{
  font-size: clamp(0.5em, 1vw + 0.5em, 0.8em);
}

.intro-video {
  margin: 20px 0;
  padding: 20px;
  display: flex;
  justify-content: center;
}

/* General input field styles */
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
select,
textarea,
.custom-file-upload {
  width: 100%;
  max-width: 400px;
  margin: 10px 0;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  font-size: clamp(0.5em, 1vw + 1em, 0.8em);
}

/* Input focus effect */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus,
.custom-file-upload {
  border-color: #38B6FF;
  outline: none;
  box-shadow: 0 0 5px rgba(56, 182, 255, 0.5);
}

/* Button styling to match registration */
button {
  margin-top: 10px;
  padding: 12px;
  background-color: #38B6FF;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: clamp(0.5em, 1vw + 0.5em, 0.8em);
}

/* Button hover effect */
button:hover {
  background-color: #17B2AC;
}

.video-preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-preview.portrait video {
  max-height: 80vh; /* Limits the height for portrait videos */
  width: auto;
}

.video-preview.landscape video {
  max-width: 100%; /* Limits the width for landscape videos */
  height: auto;
  max-height: 60vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-left: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
